<template>
  <div class="p-variant">
    <div v-if="item.variant && item.variant.length != 0 && !item.countdown && item.product_lable !== 'COMING-SOON'">
      <div class="shop-variant">
        <span v-for="(item, i) in item.variant" :key="i">
          <i :class="item.status_variant === 'sold' ? 'shop-variant-s' : 'shop-variant-a'"> {{ item.ukuran }} </i>
        </span>
      </div>
    </div>
    <div v-if="item.is_bundling">
      <div class="shop-variant">
        <span>
          <i :class="item.status === 'sold' ? 'shop-variant-s' : 'shop-variant-a'"> BUNDLING </i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductVariant',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style scoped>
.shop-variant {
  font-size: 0.8rem;
}
.shop-variant-s {
  color: #d3d3d3;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: bold;
}

.shop-variant-a {
  color: #000;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: bold;
}
</style>