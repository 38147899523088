<template>
  <div class="s-delimeter">
    <v-icon
      v-for="(icon, indexicon) in url_images" 
      :key="indexicon"
      class="mr-1" 
      :color="indexicon === active ? pointerColor : '#C0C0C0'"
      size="7"
    >
      mdi-checkbox-blank-circle
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'SliderDelimeter',
  props: {
    url_images: {
      type: Array,
      default: () => {
        return []
      }
    },
    active: {
      type: Number,
      default: 0
    },
    pointerColor: {
      type: String,
      default: '#000'
    }
  }
}
</script>