<template>
  <div class="p-label">
    <span 
      v-if="(!item.qty || item.qty === '0')" 
      :class="isMobile ? 'shop-badge-notify-m' : 'shop-badge-notify'" 
      :style="soldColor ? {'background-color': soldColor} : {'background-color': 'rgb(218,41,28)'}">
        {{ $t('shop.sold') }} 
    </span>

    <span 
      v-if="(item.product_lable === 'COMING-SOON')" 
      :class="isMobile ? 'shop-badge-notify-soon-m' : 'shop-badge-notify-soon'">
        SOON 
    </span>

    <span 
      v-if="(item.product_lable === 'ON-SALE')" 
      :class="isMobile ? 'shop-badge-notify-onsale-m' : 'shop-badge-notify-onsale'">
        ON SALE 
    </span>

    <div
      v-if="item.category_menu && item.category_menu === 'Preorder'"
      :class="getPreorderBadge(item)"
    >
      PreOrder
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductLabel',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    soldColor: {
      type: String,
      default: 'rgb(218,41,28)'
    }
  },
  methods: {
    getPreorderBadge (item) {
      if (this.isMobile) {
        if (item.thumbnail === 'BIG') {
          return 'preorder-badge pb-mobile-lg'
        } else {
          return 'preorder-badge pb-mobile-sm'
        } 
      } else {
        if (item.thumbnail === 'BIG') {
          return 'preorder-badge pb-dekstop-lg'
        } else {
          return 'preorder-badge pb-dekstop-sm'
        } 
      }
    },
  }
}
</script>

<style scoped>
.shop-badge-notify{
  background-color: rgb(218,41,28);
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 5px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 2%;
}
.shop-badge-notify-m {
  background-color: rgb(218,41,28);
  color: #FFFFFF;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 5px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 3%;
  left: 2%;
}
.shop-badge-notify-soon{
  background-color: #e7a635;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 5px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 2%;
}
.shop-badge-notify-soon-m {
  background-color: #e7a635;
  color: #FFFFFF;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 5px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 3%;
  left: 2%;
}
.shop-badge-notify-onsale{
  background-color: rgb(218,41,28);
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  right: 2%;
}
.shop-badge-notify-onsale-m {
  background-color: rgb(218,41,28);
  color: #FFFFFF;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 3%;
  right: 2%;
}
.preorder-badge {
  background-color: rgba(49, 53, 59, 0.68);
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  padding: 4px 8px;
  font-weight: bold;
}
.pb-mobile-sm {
  position: absolute;
  bottom: 4%;
  left: 4%;
}
.pb-mobile-lg {
  position: absolute;
  bottom: 3%;
  left: 4%;
}
.pb-dekstop-sm {
  position: absolute;
  bottom: 2%;
  left: 4%;
}
.pb-dekstop-lg {
  position: absolute;
  bottom: 2%;
  left: 23%;
}
</style>